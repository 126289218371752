/*
 * @Author: your name
 * @Date: 2022-04-26 13:12:00
 * @LastEditTime: 2022-04-28 15:21:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ananyuweiback\src\utils\watermark.js
 */
/**  水印添加方法  */
const setWatermark = (str1, str2) => {
  const id = '1.23452384164.123412415'

  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id))
  }

  const can = document.createElement('canvas')
  // 设置canvas画布大小
  can.width = 370
  can.height = 280

  const cans = can.getContext('2d')
  cans.rotate((-20 * Math.PI) / 180) // 水印旋转角度
  cans.font = '22px Vedana' //水印的字体大小
  cans.fillStyle = '#797979' //水印的字体颜色
  // cans.fillStyle = '#000000' //水印的字体颜色
  cans.textAlign = 'center' //水印的位置
  cans.textBaseline = 'Middle'
  cans.fillText(str1, can.width / 2, can.height) // 水印在画布的位置x，y轴
  cans.fillText(str2, can.width / 2, can.height)
  // cans.fillText(str2, can.width / 2, can.height + 30)

  const div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '10px'
  div.style.left = '0px'
  div.style.opacity = '0.15'
  div.style.position = 'fixed'
  div.style.zIndex = '1000'
  div.style.width = document.documentElement.clientWidth + 'px'
  div.style.height = document.documentElement.clientHeight + 'px'
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  document.body.appendChild(div)
  return id
}

// 添加水印方法
export const setWaterMark = (str1, str2) => {
  let id = setWatermark(str1, str2)
  if (document.getElementById(id) === null) {
    id = setWatermark(str1, str2)
  }
}

// 移除水印方法
export const removeWatermark = () => {
  const id = '1.23452384164.123412415'
  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id))
  }
}
