//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { _getworkerInfo} from "@/api/disabilities.js";
import {_getengineerDispatch} from "@/api/maintenance"


export default {
  data() {
    return {
      dialogVisible: false,
      btnDisab:false,
      form:{
          orderId:"",
          engineers:[]
      },
      engList:[],
      as:[],
    };
  },
  methods: {
    init(id) {
     this.dialogVisible = true
     this.form.orderId = id
     this.getworkerInfo()
    },
    // 获取工程师名单
      getworkerInfo(){
      _getworkerInfo({}).then((res)=>{
        this.engList = res.data
      })
    },
    // 确认
    adduser() {
       this.$refs["form"].validate((valid) => {
         if(valid){
            this.btnDisab = true
      _getengineerDispatch(this.form).then((res)=>{
          if(res.code == "1"){
            this.btnDisab = false
                   this.$router.push({
                    path: "/main/maintenance/order",
                    query: { selected: "60"},
                  });
              this.$message(res.msg)
              this.dialogVisible = false
          }else{
            this.btnDisab = false
          }
      }).catch(()=>{
        this.btnDisab = false
      })
         }
       })
     
    },
    close() {
      this.form = {};
      this.$refs["form"].resetFields();
    },
  },
};
